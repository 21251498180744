<template>
  <div class="bg-white" style="padding-top:20px;padding-left:40px;padding-bottom:100px;padding-right:40px;">
    <b-row>
      <b-col cols="3" class="text-left">
        <b-link :to="{ path: '/' }">
        <img
          alt="Vue logo"
          src="../assets/img/logo-sekolah-an-nisaa.png"
          width="80"
        />
        </b-link>
      </b-col>
      <b-col cols="6">
        <div class="text-center">
          <small class="font-weight-bold text-success"
            >PLAYGROUP | KINDERGARDEN | ELEMENTARY SCHOOL | JUNIR HIGH SCHOOL |
            SENIOR HIHG SCHOOL</small
          >
        </div>
        <b-row class="mt-4">
          <b-col>
            <table>
              <tr>
                <td width="50" class="text-center">
                  <img
                    alt="Vue logo"
                    src="../assets/img/whatsapp.svg"
                    width="30"
                  />
                </td>
                <td style="line-height: 1">
                  <small class="text-success">
                    Whatsapps<br />
                    <b>+62813-7755-9918</b>
                  </small>
                </td>
              </tr>
            </table>
          </b-col>

          <b-col>
            <table>
              <tr>
                <td width="50" class="text-center">
                  <img
                    alt="Vue logo"
                    src="../assets/img/telephone.svg"
                    width="25"
                  />
                </td>
                <td style="line-height: 1">
                  <small class="text-success">
                    Telepon<br />
                    <b>+62813-7755-9918</b>
                  </small>
                </td>
              </tr>
            </table>
          </b-col>

          <b-col>
            <table>
              <tr>
                <td width="50" class="text-center">
                  <img alt="Vue logo" src="../assets/img/map.svg" width="30" />
                </td>
                <td style="line-height: 1">
                  <small class="text-success">
                    Visit Us<br />
                    <b>Jl. Jombang Raya No. 25A Pondok Aren, Banten</b>
                  </small>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3">
          <table width="100%">
              <tr>
                  <td class="text-right" width="180px" style="display:none;"><b-button pill variant="info" :to="{ path: '/login' }" size="md" style="padding-left:30px;padding-right:30px;">Login</b-button></td>
                  <td class="text-right"><img alt="Logo Izada" src="../assets/img/logo-izada.jpg" /></td>
              </tr>
          </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
};
</script>

<style>
</style>